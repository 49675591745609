/* eslint-disable @typescript-eslint/member-ordering */
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import { map} from 'rxjs/operators';
import {ACCESS_TOKEN, LTPA_DATA} from 'src/common/token/tokens';
import {Authorization} from 'src/common/webapi/contracts/authorization';
import {LoopingHttpClient} from 'src/shared/http/loopingHttpClient';
import {isNullOrUndefined} from "../helper/object.helper";
import {environment} from "../../environments/environment";
import {LtpaData} from "../../common/webapi/contracts/ltpaData";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  //#region -- configuration --

  private static readonly urlToLogin: string = `authentication/login`;
  private static readonly urlToLtpaLogin: string = `authentication/ltpa/login`;
  private static readonly urlToLogout: string = `authentication/logout`;
  private static readonly urlToRefresh: string = `authentication/refresh/token`;

  //#endregion

  //#region -- fields --

  private readonly _httpClient: LoopingHttpClient;
  private readonly _accessToken: BehaviorSubject<Authorization>;
  private readonly _ltpData: BehaviorSubject<LtpaData>;

  //#endregion

  //#region -- constructor --

  public constructor(
    httpClient: LoopingHttpClient,
    @Inject(ACCESS_TOKEN) accessToken: BehaviorSubject<Authorization>,
    @Inject(LTPA_DATA) ltpData: BehaviorSubject<LtpaData>,
  ) {
    this._httpClient = httpClient;
    this._accessToken = accessToken;
    this._ltpData = ltpData;
  }

  //#endregion

  //#region -- methods --

  public login = (username: string, password: string): Observable<boolean> =>
    this._httpClient
      .create(AuthenticationService.urlToLogin, {username: username, password: password})
      .pipe<boolean>(
        map((result: Authorization) => {

          this._accessToken.next(result);

          return true;
        }));

  //TODO: REMOVE Clevermail - Login after/on frontend logout ... / on refresh ;)
  //Create extra route for this...
  public ltpaLogin(): Observable<boolean> {

    try {
      var pathToAutoLogin = this._httpClient.getApiPath(AuthenticationService.urlToLtpaLogin);
      console.info(`try autologin with ${pathToAutoLogin}...`);
      // standard http client von angular...

      var client = this._httpClient.getHttpClient();

      //TODO: not working on chrome - should we fix this?!
      var loginResult = client
        .get<Authorization>(pathToAutoLogin, this.getLtpaOptions());

      loginResult.pipe(
        map(result => {

            // @ts-ignore
          this._accessToken.next(result);

            return true;
          })
      );

    } catch (err) { // instead of onerror
      console.info("_httpClient ltpaLogin Request failed");
      console.info(err);
    }

    return of(false);
  }

  public logout = (): Subscription =>
    this._httpClient
      .get(AuthenticationService.urlToLogout)
      .subscribe(() => {
        this._accessToken.next(null);
        this._ltpData.next(null);
      });

  public refresh = (refreshToken: string, token: string): boolean => {
    if (isNullOrUndefined(this._accessToken.value))
      return false;

    var refreshed = false;
    this._httpClient
      .create(AuthenticationService.urlToRefresh, {refreshToken: refreshToken, oldToken: token})
      .pipe(
        map((result: Authorization) => {
          this._accessToken.next(result);

          refreshed = true;
        }));

    return refreshed;
  }

  private getLtpaOptions():any{

    var ltpaTokenName = environment.mailingConfiguration.tokenName;
    var ltpaToken = this._ltpData.value.access_token;
    var mIVGroups = this._ltpData.value.miv_groups;
    console.log("ltpatoken login in backend");
    console.log(ltpaToken);
    const httpOptions = {
      headers: {
        'Content-Type': 'application/json',
        'iv-groups': mIVGroups
      },
      withCredentials: true,
      cookie: {
        ltpaTokenName: ltpaToken
      }
    };

    return httpOptions;
  }

  //#endregion
}
