export class Authorization {
  //#region -- fields --

  public access_token: string;
  public token_type: string;
  public refresh_token: string;
  public scope: string;
  public expires_in: number;

  //#endregion

  //#region -- methods --

  public static getHeaderValue = (authorization: Authorization): string =>
    `${authorization.token_type} ${authorization.access_token}`;

  public static getAppToken = (authorization: Authorization): string =>
    authorization.scope;

  public static getExpireDateTime = (authorization: Authorization): number =>
    authorization.expires_in;

  public static getRefreshToken = (authorization: Authorization): string =>
    authorization.refresh_token;

  //#endregion
}
