import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import {ACCESS_TOKEN, LTPA_DATA} from 'src/common/token/tokens';
import { Authorization } from 'src/common/webapi/contracts/authorization';
import { isNotNullOrUndefined } from '../helper/object.helper';
import {LtpaData} from "../../common/webapi/contracts/ltpaData";
import {environment} from "../../environments/environment";
import {CookieService} from "ngx-cookie-service";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";

@Injectable()
export class LoginInterceptor
  implements HttpInterceptor {
  //#region -- configuration --

  private static readonly HeaderName: string = 'Authorization';
  private static readonly HeaderAppTokenName: string = 'App-Token';
  private static readonly LtpaLoginUrl: string = 'ltpa-token-autologin';

  //#endregion

  //#region -- fields --

  private readonly _accessToken: BehaviorSubject<Authorization>;
  private readonly _ltpaData: BehaviorSubject<LtpaData>;
  private readonly _cookieService: CookieService;
  private readonly _router: Router;

  //#endregion

  //#region -- constructor  --

  public constructor(
    injector: Injector,
    cookieService: CookieService,
    router: Router
  ) {
    this._accessToken = injector.get(ACCESS_TOKEN);
    this._ltpaData = injector.get(LTPA_DATA);
    this._cookieService = cookieService;
    this._router = router;
  }

  //#endregion

  //#region -- methods --

  public intercept = (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> => {
    console.log(req.url + " --> interceptor started");

    if(req.url.endsWith(LoginInterceptor.LtpaLoginUrl)){
      console.log(req.url + " --> setCurrentLtpaData");
      this.setCurrentLtpaData(req);
    }

    if (isNotNullOrUndefined(this._accessToken.value)){

      req = req.clone(
        {
          headers: req.headers
            .set(LoginInterceptor.HeaderName, Authorization.getHeaderValue(this._accessToken.value))
            .set(LoginInterceptor.HeaderAppTokenName, Authorization.getAppToken(this._accessToken.value))
        }
      );
    }

    return next.handle(req)
      .pipe(
        catchError(x=> this.handleAuthError(x))
      );
  };

  private setCurrentLtpaData(req: HttpRequest<any>): void {
    var ltpaAccessToken = this._cookieService.get(environment.mailingConfiguration.tokenName);
    if (ltpaAccessToken != undefined && ltpaAccessToken != null) {
      var mIvGroups = req.headers.get(environment.mailingConfiguration.mIVGroupsName);
      var ltpaData: LtpaData = {
        access_token: ltpaAccessToken,
        miv_groups: mIvGroups
      };

      this._ltpaData.next(ltpaData);

      console.log("ltpaData updated");
    }
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      //navigate /delete cookies or whatever
      this._router.navigateByUrl(`/login`);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(() => err);
  }
  //#endregion
}
