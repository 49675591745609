export const environment = {
  production: false,
  api: {
    base: 'https://dev-business.clevermail.m-result.de/api/v1.0'
  },
  mailingConfiguration: {
    baseUrl: 'https://mbkdev.skoda-dialog.de/',
    url: 'https://mbkdev.skoda-dialog.de/Default.aspx',
    tokenName: 'ltpatoken',
    mIVGroupsName: 'iv-groups',
  }
};
