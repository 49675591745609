import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { List } from 'linqts';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { applicationEnvironment } from '../../environments/application.environment';
import { JsonHelper } from '../helper/json.helper';
import { ToastHelperService } from '../services/toast.helper.service';
import { QueryStringBuilderRuleBase } from './queryStringBuilderRules/base/queryStringBuilderRuleBase';

@Injectable({
  providedIn: 'root'
})
export class LoopingHttpClient {
  //#region -- configuration --

  private static assetPrefix: string = '/assets';

  //#endregion

  //#region -- fields --

  private readonly _httpClient: HttpClient;
  private readonly _queryStringBuilderRules: List<QueryStringBuilderRuleBase>;
  private readonly _notificationService: NotificationService;
  private readonly _toastHelperService: ToastHelperService;

  //#endregion

  //#region -- constructor --

  constructor(
    @Inject(QueryStringBuilderRuleBase) queryStringBuilderRules: QueryStringBuilderRuleBase[],
    httpClient: HttpClient,
    notificationService: NotificationService,
    toastHelperService: ToastHelperService
  ) {
    this._queryStringBuilderRules = new List(queryStringBuilderRules);
    this._httpClient = httpClient;
    this._notificationService = notificationService;
    this._toastHelperService = toastHelperService;
  }

  //#endregion

  //#region -- methods --

  public getHttpClient(): HttpClient {
    return this._httpClient;
  }

  public get = <T>(endpoint: string, params?: any): Observable<T> =>
    this.callHttp<T>(() => this._httpClient.get<T>(this.getApiPath(endpoint), { params: this._queryStringBuilderRules.First(item => item.canBuild(params)).build(params) }));

  public create = <T>(endpoint: string, content: any): Observable<T> =>
    this.callHttp<T>(() => this._httpClient.post<T>(this.getApiPath(endpoint), content));

  public delete = <T>(endpoint: string, params?: any): Observable<T> =>
    this.callHttp<T>(() => this._httpClient.delete(this.getApiPath(endpoint), { params: this._queryStringBuilderRules.First(item => item.canBuild(params)).build(params) }));

  public update = <T>(endpoint: string, content: any): Observable<T> =>
    this.callHttp<T>(() => this._httpClient.put<T>(this.getApiPath(endpoint), content));

  public patch = <T>(endpoint: string, content?: any): Observable<T> =>
    this.callHttp<T>(() => this._httpClient.patch<T>(this.getApiPath(endpoint), content));

  public downloadOctetStream = (endpoint: string): Observable<Blob> =>
    this.download(this.getApiPath(endpoint), 'application/octet-stream');

  public getAsset = <T>(assetPath: string): Observable<T> =>
    this.callHttp<T>(() => this._httpClient.get<T>(Location.joinWithSlash(LoopingHttpClient.assetPrefix, assetPath)));

  public getApiPath = (endpoint: string): string =>
    Location.joinWithSlash(applicationEnvironment.api.base, endpoint);

  private download = (endpoint: string, contentType: string): Observable<Blob> =>
    this._httpClient.get(endpoint, { responseType: 'arraybuffer' })
      .pipe(
        map((result: ArrayBuffer) => new Blob([result], { type: contentType })));

  public callHttp = <T>(call: () => Observable<any>): Observable<T> =>
    call()
      .pipe(
        map(result => {
          return (typeof result === 'object')
            ? JsonHelper.resolveReferences(<string>(<any>result))
            : result;
        }),
        catchError(error => {
          this._notificationService
            .show(this._toastHelperService.httpError(error));

          return throwError(error);
        }));

  //#endregion
}
