import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPageComponent } from 'src/default-pages/not-found-page/not-found-page.component';
import { UnderConstructionComponent } from 'src/default-pages/under-construction/under-construction.component';
import { LoggedInGuard } from 'src/shared/guards/logged-in.guard';

const routes: Routes =
  [
    {
      path: '',
      canActivate:
        [
          LoggedInGuard
        ],
      children:
        [
          {
            path: 'home',
            loadChildren: () => import('../modules/home/home.module').then(m => m.HomeModule),
          },
          {
            path: 'tasks',
            loadChildren: () => import('../modules/tasks/tasks.module').then(m => m.TasksModule),
          },
          {
            path: 'company-info',
            loadChildren: () => import('../modules/company-info/company-info.module').then(m => m.CompanyInfoModule),
          },
          {
            path: 'interested-parties',
            loadChildren: () => import('../modules/interested-parties/interested-parties.module').then(m => m.InterestedPartiesModule)
          },
          {
            path: 'customers',
            loadChildren: () => import('../modules/business/customers/customers.module').then(m => m.CustomersModule)
          },
          {
            path: 'shipping-schedule',
            loadChildren: () => import('../modules/shipping-schedule/shipping-schedule.module').then(m => m.ShippingScheduleModule)
          },
          {
            path: 'shipping-preview',
            loadChildren: () => import('../modules/business/shipping-preview/shipping-preview.module').then(m => m.ShippingPreviewModule)
          },
          {
            path: 'actions',
            loadChildren: () => import('../modules/actions/actions.module').then(m => m.ActionsModule),
          },
          {
            path: 'mailing-configuration',
            loadChildren: () => import('../modules/mailing-configuration/mailing-configuration.module').then(m => m.MailingConfigurationModule)
          },
        ]
    },
    {
      path: 'login',
      loadChildren: () => import('../modules/login/login.module').then(m => m.LoginModule)
    },
    {
      path: 'todo',
      component: UnderConstructionComponent
    },
    {
      path: '**',
      component: NotFoundPageComponent
    }
  ];

@NgModule({
  imports:
    [
      RouterModule.forRoot(routes)
    ],
  exports:
    [
      RouterModule
    ]
})
export class AppRoutingModule { }

