import { ExtendedDrawerItem } from 'src/components/models/extendedDrawerItem';

export const navigationConfiguration = <ExtendedDrawerItem[]>
  [
    { text: '', icon: 'fa-solid fa-bars', extendOnClick: true },
    { text: 'NAVIGATION.HOME', icon: 'fa-solid fa-house', path: '/home' },
    { text: 'NAVIGATION.TASKS', icon: 'fa-solid fa-tasks', path: '/tasks' },
    { text: 'NAVIGATION.CUSTOMERS', icon: 'fa-solid fa-people-arrows', path: '/customers' },
    { text: 'NAVIGATION.SHIPPING_PREVIEW', icon: 'fa-solid fa-list', path: '/shipping-preview' },
    { text: 'NAVIGATION.MAILING_CONFIGURATION', icon: 'fa-solid fa-envelopes-bulk', path: '/mailing-configuration' },
    { text: 'Kontakt- und Absenderdaten', icon: 'fa-solid fa-address-book', path: '/mbk/dealer-and-sender' },
    { text: 'Ansprechpartner und Unterschriften', icon: 'fa-solid fa-address-card', path: '/mbk/signatures' },
    { text: 'Dialogkonfiguration', icon: 'fa-solid fa-table', path: '/mbk/mal-dialog-overview' },
    { text: 'Vertriebs- und Servicemailings (DEPRECATED)', icon: 'fa-solid fa-clipboard-list', path: '/mbk/recent-mailings' },
    { text: 'Kundendatenprüfung', icon: 'fa-solid fa-clipboard-check', path: '/mbk/customer-validation' },
    { text: 'Überführungskosten', icon: 'fa-solid fa-globe', path: '/mbk/mal-transfer-cost' },
    { text: 'Verwaltung CSS und Reputation Data', icon: 'fa-solid fa-building-user', path: '/mbk/css' },
    { text: 'Mystery Shopping', icon: 'fa-solid fa-universal-access', path: '/mbk/mystery-shopping' },
    { text: 'Clever Service und Zentr. Preisformular', icon: 'fa-solid fa-network-wired', path: '/mbk/clever-service' },
    { text: 'mal 2 - Teileaufschlag', icon: 'fa-solid fa-clipboard-list', path: '/mbk/mal-2' }
  ];
