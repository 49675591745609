import { InjectionToken } from '@angular/core';
import { Mapper } from '@automapper/types';
import { BehaviorSubject } from 'rxjs';
import { Authorization } from '../webapi/contracts/authorization';
import { LtpaData } from "../webapi/contracts/ltpaData";
import { MbkData } from "../webapi/contracts/mbkData";

export const MAPPER = new InjectionToken<Mapper>('MAPPER');
export const ACCESS_TOKEN = new InjectionToken<BehaviorSubject<Authorization>>('ACCESS_TOKEN');
export const LTPA_DATA = new InjectionToken<BehaviorSubject<LtpaData>>('LTPA_TOKEN');
export const MBK_DATA = new InjectionToken<BehaviorSubject<MbkData>>('MBK_DATA');
